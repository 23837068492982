<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit">
        </AConfirmation>
        <v-btn
        class="my-3"
        color="primary"
        @click="closeDetail">
            <v-icon>
                mdi-arrow-left-bold
            </v-icon>
        </v-btn>
        <v-skeleton-loader
        ref="skeleton"
        type="table"
    v-if="this.api.isLoading">
        </v-skeleton-loader>
        <v-card
        v-if="!this.api.isLoading">
            <v-card-title class="secondary text-h6 indigo white--text">
                {{ localCompanies.name }}
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field
                            v-model="localCompanies.name"
                            label="company name">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                    </v-row>
                    <v-row>
                        <v-text-field
                        prepend-inner-icon="mdi-map-marker"
                        clearable
                        v-model = "localCompanies.address"
                        label="address">
                        </v-text-field>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                            v-model="localCompanies.industry"
                            label="industry">
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                            v-model="localCompanies.company_size"
                            type="number"
                            label="company size"
                            min="0">
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row
                    class="mx-auto">
                        <v-btn
                        color="primary"
                        class="mr-2"
                        :loading="this.api.isLoading"
                        @click="validateInput">
                            Save
                        </v-btn>
                        <v-btn
                        color="primary"
                        plain
                        @click="$emit('closeDialogEvent')">
                            Cancel
                        </v-btn>
                    </v-row>
                  
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import AConfirmation from '../common/AConfirmation.vue';
export default{
    components:{
        AConfirmation
    },
    props: [
        "dialog",
    ],
    data: () => ({
        isUpdated:false,
        isPending:false,
        editedCompanies:null,
        salesPerson:null,   
        planAvailable:[null],
        api: {
            isLoading: false,
            isError: false,
            isSuccesfull:false,
            error: null,
            url: null,
        },
        localCompanies: null,
        localDialog: false,
    }),
    created() {
       
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
          
            if(resp.class=="getCompany"){
                this.localCompanies = resp.data;
            }
            if(resp.class=="updateCompanies"){
                this.$router.push({name:'PageCompaniesDetail'});
                
            }
            this.api.isLoading = false;
            return resp.data;
            
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.isUpdated = false;
            let fetchCompanyApi = this.fetchCompany();
            this.$axios.all([
                this.$api.fetch(fetchCompanyApi),
            ]);
        },
        fetchCompany(){
            let companyId = this.$router.history.current.params.id;
            let tempApi = this.$_.clone(this.api);
            tempApi.method  = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/companies/"+companyId;
            return tempApi;
        },
        validateInput(){
            this.isPending=true;
        },
        submit(){
            this.isPending = false;
            let updateCompaniesApi=this.updateCompanies();
            this.$api.fetch(updateCompaniesApi).then(()=>{
                this.isUpdated = true;
            });
        },
        cancelSubmit(){
            this.isPending = false;
        },
        closeDetail(){
            this.$router.go(-1);
        },
        updateCompanies() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "post";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/company/update";
            tempApi.params = {
                'id':this.localCompanies.companies_id,
                'name':this.localCompanies.name,
                'address':this.localCompanies.address,
                'industry':this.localCompanies.industry,
                'companySize':this.localCompanies.company_size,
            };
            return tempApi;
        }
    },
}
</script>